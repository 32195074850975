<template>
  <div class="page">
    <div class="inputbox">
      <div class="title">添加宝贝名称</div>
      <input type="text" v-model="goodsName" placeholder="请输入宝贝名称" />
    </div>
    <div class="inputbox">
      <div class="title">添加宝贝内容</div>
      <div class="content_box">
        <van-field
          rows="6"
          v-model="goodsContent"
          style="background: rgba(0, 0, 0, 0.04); border-radius: 16px"
          type="textarea"
          placeholder="描述型号，入手渠道，转手原因…"
          maxlength="300"
          show-word-limit
        />
      </div>
    </div>
    <div class="main-title" style="padding-bottom: 0">上传宝贝图片</div>
    <div class="bottom_box" style="margin-top: 0">
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="goodsPhoto"
        :activityPicture.sync="goodsPhoto"
        :maxCount="9"
        ref="load"
      ></v-upload>
    </div>
    <div class="main-title">物品类型</div>
    <div class="tabList">
      <div
        @click="tabChoose(t)"
        :class="['tab-item', { 'tab-item-act': t.select }]"
        v-for="(t, i) in tabList"
        :key="i"
      >
        {{ t.name }}
      </div>
    </div>
    <div class="line"></div>
    <div class="input-line">
      <div class="left">价格</div>
      <input type="text" v-model="goodsPrice" placeholder="输入价格" />
    </div>
    <!-- <div class="input-line">
      <div class="left">发布人</div>
      <input type="text" placeholder="输入发布人" />
    </div> -->
    <div class="btn" @click="submit">{{ btnStr }}</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { goodsTypeList } from "./map";
import { addGoodsUrl, compileGoodsUrl, goodThingsDetailsUrl } from "./api";
export default {
  name: "publishGoodThingsAdd",
  data() {
    return {
      btnStr: "发布",
      goodsName: null,
      goodsContent: null,
      goodsPhoto: null,
      goodsType: 1,
      goodsPrice: null,
      tabList: goodsTypeList,
      goodsId: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    if (this.$route.query.goodsId) {
      this.goodsId = this.$route.query.goodsId;
      this.getDetail(this.$route.query.goodsId);
    }
  },
  mounted() {},
  methods: {
    getDetail(id) {
      let params = {
        goodsId: id,
        userId: this.userId,
      };
      this.$axios
        .get(`${goodThingsDetailsUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.btnStr = "编辑";
            this.goodsName = res.data.goodsName;
            this.goodsContent = res.data.goodsContent;
            this.goodsPhoto = res.data.goodsPhoto;
            this.goodsPrice = res.data.goodsPrice;
            this.goodsType = res.data.goodsType;
            this.tabList.forEach((item) => {
              item.select = false;
              if (item.id == this.goodsType) {
                item.select = true;
              }
            });
          }
        });
    },
    submit() {
      if (!this.goodsName) {
        this.$toast("请输入宝贝名称！");
        return;
      }
      if (!this.goodsContent) {
        this.$toast("请输入宝贝内容！");
        return;
      }
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.goodsPrice) {
        this.$toast("请上传好物图片！");
        return;
      }
      if (!this.goodsPrice) {
        this.$toast("请输入宝贝价格！");
        return;
      }
      if (this.goodsPrice == "0" || isNaN(Number(this.goodsPrice))) {
        this.$toast("请输入正确的宝贝价格！");
        return;
      }
      let params = {
        userId: this.userId,
        goodsName: this.goodsName,
        goodsContent: this.goodsContent,
        goodsPrice: this.goodsPrice,
        goodsPhoto: this.goodsPhoto,
        goodsType: this.goodsType,
        tenantId: this.tenantId,
        goodsId: this.goodsId,
      };
      let url = this.goodsId ? compileGoodsUrl : addGoodsUrl;
      this.$axios.post(`${url}`, params).then((res) => {
        if (res.code == 200) {
          this.$toast(res.msg);
          this.timer = setTimeout(() => {
            this.$router.back();
          }, 200);
        }
      });
    },
    tabChoose(t) {
      this.tabList.forEach((item) => {
        item.select = false;
      });
      this.goodsType = t.id;
      t.select = true;
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 60px 30px;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    margin: 60px auto;
  }
  .input-line {
    display: flex;
    padding: 30px 0;
    .left {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      width: 100px;
    }
    input {
      flex: 1;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      text-align: right;
    }
  }
  .line {
    height: 50px;
    border-bottom: 2px solid #f5f5f5;
  }
  .tabList::-webkit-scrollbar {
    display: none;
  }
  .tabList {
    height: 52px;
    display: flex;
    line-height: 52px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    overflow-x: scroll;
    flex-wrap: nowrap;
    .tab-item-act {
      font-weight: 600;
      background: #fcf3ec !important;
      color: #e89645;
    }
    .tab-item {
      height: 100%;
      min-width: 154px;
      background: #f7f7f7;
      border-radius: 26px;
      text-align: center;
      margin-right: 20px;
    }
  }
  .main-title {
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    padding-bottom: 32px;
  }
  .bottom_box {
    width: 100%;
    min-height: 200px;
    display: flex;
    margin-top: 50px;
    margin-bottom: 76px;

    .oItem-b_upload {
      margin: 32px 0 16px;

      ::v-deep .van-uploader__preview {
        border: 2px dashed #eee;
      }

      ::v-deep .van-uploader__preview-image {
        width: 208px !important;
        height: 208px !important;
        box-sizing: border-box;
      }

      ::v-deep .van-uploader__upload {
        width: 208px !important;
        height: 208px !important;
        box-sizing: border-box;
      }

      ::v-deep .van-uploader__upload {
        margin: 0;
      }
    }
  }
  .inputbox {
    margin-bottom: 20px;
    .title {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 10px;
    }
    input {
      width: 100%;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>